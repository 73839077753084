import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnInit,
  Output,
  viewChild,
} from '@angular/core';
import {
  FfNgxButtonComponent,
  FfNgxCardComponent,
  FfNgxFormFieldComponent,
  FfNgxFormFieldErrorComponent,
  FfNgxIconComponent,
  FfNgxInputContainerComponent,
  FfNgxInputDirective,
  FfNgxLoadingIndicatorComponent,
} from '@fagforbundet/ngx-components';
import { AsyncPipe } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, finalize, take } from 'rxjs/operators';
import { BankAccountNumberValidators } from '@shared/validators/bank-account.validator';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'ff-ngx-bank-account-number-form',
  templateUrl: './ff-ngx-bank-account-number-form.component.html',
  styleUrls: ['./ff-ngx-bank-account-number-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    FfNgxButtonComponent,
    FfNgxCardComponent,
    FfNgxIconComponent,
    FfNgxLoadingIndicatorComponent,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FfNgxFormFieldComponent,
    FfNgxFormFieldErrorComponent,
    FfNgxInputContainerComponent,
    FfNgxInputDirective,
  ],
})
export class FfNgxBankAccountNumberFormComponent implements OnInit {
  #fb = inject(FormBuilder);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  disableCancel: boolean = false;
  bankAccountNumber$ = input.required<Observable<string>>();
  putBankAccountNumber = input.required<(value: string) => Observable<string>>();
  inputRef = viewChild<ElementRef<HTMLInputElement>>('input');
  processing: boolean = false;
  bankAccountNumberSubject$ = new BehaviorSubject<string | null>(null);
  bankAccountNumberObservable$ = this.bankAccountNumberSubject$.asObservable();
  warningSubject$ = new BehaviorSubject<string[] | null>(null);
  form = this.#fb.group({
    bankAccountNumber: this.#fb.control(null, Validators.compose([BankAccountNumberValidators.required, BankAccountNumberValidators.valid])),
  });
  @Output()
  statusChanges = this.form.statusChanges;

  ngOnInit(): void {
    this.bankAccountNumber$().pipe(
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe((value: string) => {
      this.bankAccountNumberSubject$.next(value);
    });

    this.bankAccountNumberObservable$
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((bankAccountNumber) => {
        this.disableCancel = !bankAccountNumber;

        this.form.setControl(
          'bankAccountNumber',
          this.#fb.control(bankAccountNumber, Validators.compose([BankAccountNumberValidators.required, BankAccountNumberValidators.valid])),
        );

        if (this.disableCancel) {
          this.enableForm();
          this.#cdr.detectChanges();
          return;
        }

        this.disableForm();
        this.#cdr.detectChanges();
      });
  }

  clearWarnings(): void {
    this.warningSubject$.next(null);
  }

  handleSubmit(): void {
    this.clearWarnings();
    if (this.form.valid) {
      this.form.disable();
      this.processing = true;
      this.putBankAccountNumber()((this.form.controls.bankAccountNumber.value as string).replace(/\./g, '')).pipe(
        take(1),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {
          this.processing = false;
          this.#cdr.detectChanges();
        }),
      ).subscribe((bankAccountNumber) => {
        this.bankAccountNumberSubject$.next(bankAccountNumber);
      });
    }
  }

  disableForm(): void {
    this.form.disable({ emitEvent: false });
  }

  enableForm(): void {
    this.form.enable();
    this.#cdr.detectChanges();
    this.inputRef().nativeElement.focus();
  }
}
