import { Role as RoleEntity } from '@core/entities/user/role.entity';
import { Role } from '@core/models/user/role';
import { Roles } from '@core/models/user/roles';

export function mapSerializrRoleEntity(r: RoleEntity): Role {
  return {
    uuid: r.uuid,
    role: Roles[r.role],
    children: r.children.map(mapSerializrRoleEntity)
  };
}
