import { AsyncValidator, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, Observable, of, switchMap, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { FfNgxPhoneNumberHelper, FfNgxRequestService } from '@fagforbundet/ngx-components';
import idApiConfig from '@config/apis/id/id-api.config';
import {
  GetValidatePhoneNumberResponse,
} from '@core/models/responses/id-api/get-validate-phone-number/get-validate-phone-number-response';
import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root',
})
export class AsyncPhoneNumberValidator implements AsyncValidator {
  constructor(private readonly _requestService: FfNgxRequestService) {
  }

  validate: AsyncValidatorFn =
    ({value: { countryCode, number }, }): Observable<ValidationErrors | null> => {
    let phoneNumber: PhoneNumber;

    try {
      phoneNumber = parsePhoneNumber(FfNgxPhoneNumberHelper.concatPhoneNumber(number, countryCode.phoneCode));
    } catch (e) {
      return of(null);
    }

    return timer(200).pipe(
      switchMap(() => {
        return this._requestService
          .get<GetValidatePhoneNumberResponse>({
            api: idApiConfig,
            endpoint: idApiConfig.endpoints.GET_VALIDATE_PHONE_NUMBER,
            endpointVars: {
              phoneNumber: `+${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber}`,
            },
          })
          .pipe(
            map((response) => {
              if (response.responseBody.valid) {
                return null;
              }

              return {
                invalid: {},
              };
            }),
          );
      }),
    );
  };
}
