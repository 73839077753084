<ff-ngx-card>
  <h2>Bankkontonummer</h2>
  @if (form.disabled) {
    <div class='bank-account-number-container'>
      <ff-ngx-icon name='24x24-thin/payment-card' [size]='24' />
      <span>{{ bankAccountNumberObservable$ | async | bankAccount }}</span>
    </div>
    <div class='buttons buttons--inline'>
      <button ffNgxButton (click)='enableForm()' size='small' color='sand'>
        Rediger
      </button>
      @if (processing) {
        <ff-ngx-loading-indicator
          size='small'
          stroke='#3c3c3b'
        />
      }
    </div>
  }

  @if (form.enabled) {
    <ff-ngx-form-field>
      <ff-ngx-input-container>
        <input
          #input
          ffNgxInput
          id='bank-account'
          placeholder='11 siffer'
          type='text'
          inputmode='numeric'
          [formControl]='form.controls.bankAccountNumber'
        />
      </ff-ngx-input-container>
      <ff-ngx-form-field-error>
        @if (form.controls.bankAccountNumber.hasError('bankAccountNumberRequired')) {
          <span>Bankkontonummer må fylles ut</span>
        } @else if (form.controls.bankAccountNumber.hasError('invalidBankAccount')) {
          <span>Dette ser ikke ut som et gyldig bankkontonummer</span>
        }
      </ff-ngx-form-field-error>
    </ff-ngx-form-field>

    <div class='buttons buttons--inline'>
      <button ffNgxButton size='medium' (click)='handleSubmit()'>Lagre</button>
      <button
        ffNgxButton
        size='medium'
        color='secondary'
        [disabled]='disableCancel'
        (click)='disableForm()'
      >
        Avbryt
      </button>
    </div>
  }

</ff-ngx-card>
